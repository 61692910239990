<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {required} from "vuelidate/lib/validators"
import Swal from "sweetalert2"


import {
  createOperator,
  deleteOperator,
  editOperator,
  getOperator,
  getOperators
} from '../../../services/operators.service'

import {getUserParkings} from '../../../services/parkings.service'

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Operadores",
      items: [
        {
          text: "MaxParking"
        },
        {
          text: "Operadores",
          active: true
        }
      ],
      operatorData: [],
      userParkings: [],
      parkingOptions: [],
      operator: {
          user_id: "",
          operator_id: "",
          parking_id: "",
          password: "",
      },
      editing_id: "",
      submitted: false,
      showmodal: false,
      edit_showmodal: false,
      createError: "",
      isCreateError: false,
      editError: "",
      isEditError: false
    };
  },
  async mounted() {
    this.operatorData = await getOperators()
    this.userParkings = await getUserParkings()
    this.parkingOptions = this.getParkingOptions()
  },
  validations: {
    operator: {
        operator_id: { required },
        parking_id: { required },
        password: { required }
    },
  },
  methods: {
    hideModal() {
      this.operator = {
        user_id: "",
        operator_id: "",
        parking_id: "",
        password: "",
      }
      this.submitted = false
      this.showmodal = false
      this.isCreateError = false
      this.createError = ""
    },
    hideEditModal() {
      this.operator = {
        user_id: "",
        operator_id: "",
        parking_id: "",
        password: "",
      }
      this.isEditError = false
      this.editError = ""
      this.edit_showmodal = false
    },
    async create() {
      this.submitted = true
      this.isCreateError = false
      this.createError = ""
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('invalid')
        return
      } else {
        const result = await createOperator(this.operator)

        if (result.status >= 400) {
            if (result.status === 400) {
                const errors = Object.values(result.errors)
                errors.forEach(error => this.createError += (this.createError === null) ? '': `${error[0]} <br/>` )
            } else {
                this.createError = result.errors.status
            }
            this.isCreateError = true
        } else {
            this.operatorData = await getOperators()
            this.showmodal = false
        }
      }

      this.submitted = false
      this.operator = {
        user_id: "",
        operator_id: "",
        parking_id: "",
        password: "",
      }
    },
    async edit() {
      this.isEditError = false
      this.editError = ""
      const operatorData = this.operator
      operatorData._id = this.editing_id

      const result = await editOperator(operatorData)
      if (result.status >= 400) {
            if (result.status === 400) {
                const errors = Object.values(result.errors)
                errors.forEach(error => this.editError += (this.editError === null) ? '': `${error[0]} <br/>` )
            } else {
                this.editError = result.errors.status
            }
            this.isEditError = true
        } else {
            this.operatorData = await getOperators()
            this.edit_showmodal = false
        }
    },
    async loadEdit(id) {
      this.editing_id = id
      this.operator = await getOperator(id)

      this.edit_showmodal = true
    },
    async remove(id) {
      Swal.fire({
        title: "¿Esta seguro?",
        text: "Borrar un operador no es reversible.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Si, borrar!",
        cancelButtonText: "Cancelar"
      }).then(async (result) => {
        if (result.value) {
            await deleteOperator(id)
            this.operatorData = await getOperators()
            Swal.fire("Borrado!", "El operador ha sido eliminado.", "success");
        }
      })
    },
    getParkingName(id) {
      const parking = this.userParkings.filter(parking => parking._id == id)
      if(parking.length > 0) {
        return parking[0].name
      }
      return ''
    },
    getParkingOptions() {
      const parkingOptions = this.userParkings.map((parking) => { 
        return {
          value: parking._id, 
          text: parking.name
        }
      })

      return parkingOptions
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <a href="javascript:void(0);" class="btn btn-success mb-2" @click="showmodal = true">
                <i class="mdi mdi-plus mr-2"></i> Agregar Operador
              </a>
            </div>
            <div class="table-responsive mt-3">
              <table
                class="table table-centered datatable dt-responsive nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;"
              >
                <thead class="thead-light">
                  <tr>
                    <th>Nombre</th>
                    <th>Parqueo Asignado</th>
                    <th>Hora Ultima Entrada</th>
                    <th>Hora Ultima Salida</th>
                    <th style="width: 120px;">Acción</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in operatorData" :key="index">
                    <td>{{item.operator_id}}</td>
                    <td>{{getParkingName(item.parking_id)}}</td>
                    <td>{{item.shift_start}}</td>
                    <td>{{item.shift_end}}</td>
                    <td>
                      <a
                        @click="loadEdit(item._id)"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        title="Editar Información"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        @click="remove(item._id)"
                        class="text-danger"
                        v-b-tooltip.hover
                        title="Borrar"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <!-- CREATE Modal -->
    <b-modal
      id="modal-1"
      v-model="showmodal"
      title="Agregar un operador"
      title-class="text-dark font-18"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
        <b-alert
            v-model="isCreateError"
            class="mt-3"
            variant="danger"
            dismissible>
            <span v-html="createError"></span>
        </b-alert>
        <form @submit.prevent="create">
            <div class="form-group">
              <label for="operator_id">ID del Operador</label>
              <input
                  id="operator_id"
                  v-model="operator.operator_id"
                  type="text"
                  class="form-control"
                  placeholder="ID del operador"
                  :class="{ 'is-invalid': submitted && $v.operator.operator_id.$error }"
              />
              <div
                  v-if="submitted && !$v.operator.operator_id.required"
                  class="invalid-feedback"
              >El nombre del operador es requerido</div>
            </div>
            
            <div class="form-group">
              <label for="parking_id">Parqueo Asignado</label>
              <b-form-select 
                v-model="operator.parking_id" 
                :options="parkingOptions"
                :class="{ 'is-invalid': submitted && $v.operator.parking_id.$error }"></b-form-select>
              <div
                  v-if="submitted && !$v.operator.parking_id.required"
                  class="invalid-feedback"
              >El parqueo asignado es invalido</div>
            </div>

            <div class="form-group">
              <label for="password">Contraseña</label>
              <input
                  id="password"
                  v-model="operator.password"
                  type="password"
                  class="form-control"
                  placeholder="Ingrese la contraseña intencional"
                  :class="{ 'is-invalid': submitted && $v.operator.password.$error }"
              />
              <div
                  v-if="submitted && !$v.operator.password.required"
                  class="invalid-feedback"
              >La contraseña de operador es requerida</div>
            </div>
            <div class="text-right">
            <button type="submit" class="btn btn-success">Guardar</button>
            <b-button class="ml-1" variant="danger" @click="hideModal">Cancelar</b-button>
            </div>
        </form>
    </b-modal>
    <!-- end create modal -->

    <!-- EDIT Modal -->
    <b-modal
      id="modal-1"
      v-model="edit_showmodal"
      title="Editar un operador"
      title-class="text-dark font-18"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
        <b-alert
            v-model="isEditError"
            class="mt-3"
            variant="danger"
            dismissible>
            <span v-html="editError"></span>
        </b-alert>
        <form @submit.prevent="edit">
            <div class="form-group">
              <input
                  v-model="editing_id"
                  type="hidden"
              />
              <label for="operator_id">ID Del Operador</label>
              <input
                  id="operator_id"
                  v-model="operator.operator_id"
                  type="text"
                  class="form-control"
                  placeholder="Ingrese un nombre de usuario para el operador"
                  :class="{ 'is-invalid': submitted && $v.operator.operator_id.$error }"
              />
              <div
                  v-if="submitted && !$v.operator.operator_id.required"
                  class="invalid-feedback"
              >El nombre del parqueo es requerido</div>
            </div>

            <div class="form-group">
              <label for="parking_id">Parqueo Asignado</label>
              <b-form-select 
                v-model="operator.parking_id" 
                :options="parkingOptions"
                :class="{ 'is-invalid': submitted && $v.operator.parking_id.$error }"></b-form-select>
              <div v-if="submitted && $v.operator.parking_id.$error" class="invalid-feedback">
                  <span v-if="!$v.operator.parking_id.required">Debe asignar un parqueo</span>
              </div>
            </div>
            <div class="form-group">
              <label for="hfee">Contraseña</label>
              <input
                  id="password"
                  v-model="operator.password"
                  type="text"
                  class="form-control"
                  placeholder="Ingrese la contraseña de operador"
                  :class="{ 'is-invalid': submitted && $v.operator.password.$error }"
              />
              <div
                  v-if="submitted && !$v.operator.password.required"
                  class="invalid-feedback"
              >La tarifa por hora es requerida</div>
            </div>
  
            <div class="text-right">
            <button type="submit" class="btn btn-success">Save</button>
            <b-button class="ml-1" variant="danger" @click="hideEditModal">Cancelar</b-button>
            </div>
        </form>
    </b-modal>
    <!-- end edit modal -->
  </Layout>
</template>