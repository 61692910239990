const axios = require('axios')
const api_base = process.env.VUE_APP_API_BASE_URL

const userService = require('./user.service')

const getOperators = async () => {
    const me = await userService.me()
    const url = `${api_base}/v1/operators/${me._id}`

    const operators = await axios.get(url)
        .catch(() => {
            console.log('ERROR GETTING OPERATORS')
            return {
                data: {
                    operators: []
                }
            }
        })
    
    return operators.data.operators
}

const createOperator = async (operator) => {
    const me = await userService.me()
    const url = `${api_base}/v1/operators`

    operator.user_id = me._id

    const result = await post(url, operator)
    return result
}

const getOperator = async (id) => {
    const url = `${api_base}/v1/operators/user/${id}`

    const operator = await axios.get(url)
    .catch(() => {
        console.log('ERROR GETTING OPERATOR')
        return {
            data: {
                parking: {}
            }
        }
    })

    return operator.data.operator
}

const editOperator = async (operator) => {
    const url = `${api_base}/v1/operators`

    const result = await patch(url, operator)
    .catch(() => {
        console.log('ERROR UPDATING OPERATOR')
        return {
            data: {
                operator: {}
            }
        }
    })

    return result
}

const deleteOperator = async (operator_id) => {
    const url = `${api_base}/v1/operators`
    const me = await userService.me()

    return await del(url, {
        data: {
            user_id: me._id,
            operator_id,
        }
    }).catch((error) => {
        console.log('ERROR DELETING OPERATOR')
        const retError = {
            errors: error.response.data
        }

        retError.status = error.response.status

        return retError
    })
}

// PRIVATES
const post = async (url, data) => {
    const result = await axios.post(url, data)
        .catch((error) => {
            const retError = {
                errors: error.response.data
            }

            retError.status = error.response.status
            
            return retError
        })

    return result
}

const patch = async (url, data) => {
    const result = await axios.patch(url, data)
        .catch((error) => {
            const retError = {
                errors: error.response.data
            }

            retError.status = error.response.status
            
            return retError
        })

    return result
}

const del = async (url, data) => {
    const result = await axios.delete(url, data)
        .catch((error) => {
            const retError = {
                errors: error.response.data
            }

            retError.status = error.response.status
            
            return retError
        })

    return result
}

module.exports = {
    getOperators,
    createOperator,
    getOperator,
    editOperator,
    deleteOperator
}